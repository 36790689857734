import React from "react";
import { Section } from "@soundtrackyourbrand/ui/src/Section";
import { graphql } from 'gatsby'
import PropTypes from "prop-types";
import * as VideoPlayerStyling from "./videoplayer.module.scss";
import Video from "../../Video/Video";

const VideoPlayer = ({block}) => {

  const videoUrl = block.video.video.mp4Url

  return (
    <Section>
      <div className="row">
        <div className="col sm-12">
          <Video src={videoUrl} className={VideoPlayerStyling.videoPlayer} controlPlayer={true} />
        </div>
      </div>
    </Section>
  );
};

export default VideoPlayer;


VideoPlayer.propTypes = {
  block: PropTypes.shape({
    video: PropTypes.shape({
      video: PropTypes.shape({
        mp4Url: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }),
};

export const query = graphql`
  fragment VideoPlayer on DatoCmsVideoplayer {
    model {
      apiKey
    }
    id: originalId
    video {
      video {
        mp4Url(res: medium)
      }
    }
  }
`